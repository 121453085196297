import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../../components/seo'
import Title from '../../components/title'
import Header from '../../components/header'
import Container from '../../components/container'
import FeatureBlock from '../../components/feature-block'
import Layout from '../../layout'
import Image from '../../components/image'
import Brands from '../../components/brands'
import AnimatedArticle from '../../components/animated-article'
import AnimatedAside from '../../components/animated-aside'
import P from '../../components/paragraph'
import BrandBadge from '../../components/brand-badge'

const HoratioPage = () => {
  const images = useStaticQuery(graphql`
    query {
      bottle: file(relativePath: { eq: "brands/horatio/horatio-bottle.png" }) {
        childImageSharp {
          fluid(maxWidth: 770) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      group: file(relativePath: { eq: "brands/horatio/horatio-group.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title='Horatio'
        description={`
          Horatio London is an independent British perfume house founded to
          capture our memories and desires in a breath taking crystal art glass
          bottle. At Horatio London they believe that creating fragrances is an
          art.
        `}
      />

      <Header />

      <Container wide noPadding='top'>
        <FeatureBlock>
          <AnimatedArticle onLoad badge>
            <Title fancy>Horatio</Title>
            <P>
              Horatio London is an independent British perfume house founded to
              capture our memories and desires in a breath taking crystal art
              glass bottle. At Horatio London we believe that creating
              fragrances is an art. To find yourself wrapped in an enchanting
              scent is an unforgettable experience like no other kind.
              Distinctive and original, each fragrance is handcrafted by British
              artisans and displays the creative genius of a perfumer dedicated
              to the fine art of perfumery and perfection.
            </P>
            <BrandBadge brand='horatio' />
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              css={`
                @media(min-width: ${({ theme }) => theme.breakpoint.large}) {
                  margin-top: 100px;
                }
              `}
              fluid={images.bottle.childImageSharp.fluid}
              notch='bottomLeftSmall'
              alt='Horatio distribution'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      <Container
        width={1100}
        noPadding
        overflow
        css='margin-top: -50px;'
      >
        <FeatureBlock grey={false} center>
          <AnimatedAside right>
            <Image
              fluid={images.group.childImageSharp.fluid}
              shadow={false}
              alt='Luxury beauty product shipping'
              css={`
                max-width: 300px;
                @media(max-width: ${({ theme }) => theme.breakpoint.large}) {
                  max-width: 200px;
                  margin: 0 auto;
                }
              `}
            />
          </AnimatedAside>
          <AnimatedArticle>
            <P>
              Ambassadors of the finest British traditions of quality and style,
              Horatio London proudly pays homage to the past whilst creating a
              perfect fragrance for tomorrow, with irresistible timeless scents
              that transcend gender and celebrate the essence of life. For the
              perfume enthusiast who adores luxury and seeks exclusivity -
              Welcome to Horatio London. The perfume of choice for pure British
              luxury.
            </P>
          </AnimatedArticle>
        </FeatureBlock>
      </Container>

      <Container width={1200}>
        <Title fancy>Other Brands</Title>
        <Brands />
      </Container>
    </Layout>
  )
}

export default HoratioPage
